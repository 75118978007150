@mind-primary-background-color: rgba(245, 245, 245, 1);
.mind-tool-window {
    position: relative;
    width: 420px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    padding: 0;
    margin-top: 1px;
    overflow: hidden;
    flex: 0 0 420px;
    border-left: 1px solid rgba(232, 232, 232, 1);

    .mind-tool-content {
        width: 420px;
        height: 100%;
        margin: 0;
        padding: 0;
        position: relative;
        display: block;
        overflow: hidden;
        background-color: #fff;

        @tabTitleHeight: 30px;
        /*#region 设置antd的tabs样*/
        // 添加高度100% 以便内部添加滚动条
        .ant-tabs,
        .ant-tabs-tabpane-active {
            height: 100%;
            width: 100%;
        }

        .ant-tabs-content {
            width: 100%;
            height: calc(~'100% - @{tabTitleHeight}');
            position: relative;
            overflow: hidden;
            overflow-y: auto;
        }

        /*#endregion*/

        .pane-title {
            position: relative;
            font-size: 16px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 28px;
            padding: 13px 3px;
            margin: 0px 12px 0px 8px;
            border-bottom: 1px solid rgba(237, 237, 237, 1);
            word-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
        }

        .no-selection-item {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(~'100% - @{tabTitleHeight}');
            background-color: #fff;
            @blankTextTop: 80px;

            .blank-text {
                width: 200px;
                min-height: 60px;
                background: url('../../../assets/svg-icons/blank-page-bg.svg') no-repeat center top;
                position: absolute;
                top: calc(~'50% - @{blankTextTop}');
                left: 110px;
                text-align: center;
                padding-top: 110px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
            }
        }

        .ant-tabs-bar {
            margin: 0 0;
            height: 30px;
            background-color: @mind-primary-background-color;

            .discussion-tab-icon,
            .style-tab-icon,
            .members-tab-icon,
            .shortcut-tab-icon {
                background-color: #333;
                width: 15px;
                height: 15px;
                display: inline-block;
            }

            .discussion-tab-icon {
                -webkit-mask: url(../../../assets/svg-icons/discussion-tab.svg) no-repeat 50% 50%;
                mask: url(../../../assets/svg-icons/discussion-tab.svg) no-repeat 50% 50%;
            }

            .style-tab-icon {
                width: 16px;
                -webkit-mask: url(../../../assets/svg-icons/style-tab.svg) no-repeat 50% 50%;
                mask: url(../../../assets/svg-icons/style-tab.svg) no-repeat 50% 50%;
            }

            .members-tab-icon {
                -webkit-mask: url(../../../assets/svg-icons/members-tab.svg) no-repeat 50% 50%;
                mask: url(../../../assets/svg-icons/members-tab.svg) no-repeat 50% 50%;
            }

            .shortcut-tab-icon {
                width: 16px;
                -webkit-mask: url(../../../assets/svg-icons/shortcut-tab.svg) no-repeat 50% 50%;
                mask: url(../../../assets/svg-icons/shortcut-tab.svg) no-repeat 50% 50%;
            }

            .ant-tabs-tab {
                border-width: 0 !important;
                margin-right: 0 !important;
                background-color: @mind-primary-background-color !important;
                padding: 3px 8px !important;
                border-radius: 0 !important;
            }

            .ant-tabs-tab-active {
                background-color: #fff !important;
            }
        }

        .mind-members-tab,
        .mind-discussion-tab,
        .mind-shortcut-tab,
        .mind-style-pane {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
        }

        .mind-discussion-tab {
            .btn-area {
                padding: 2px 10px;
                position: relative;
                width: 100%;
                text-align: right;

                .submit-btn :hover {
                    opacity: 0.8;
                }

                .submit-btn {
                    cursor: pointer;
                    border-width: 0px;
                    display: inline-block;
                    vertical-align: middle;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    line-height: 14px;
                    width: 46px;
                    height: 22px;
                    background: rgba(60, 68, 84, 1);
                    border-radius: 4px;
                }

                .submit-btn-disabled {
                    background: rgba(0, 0, 0, 0.25);
                    cursor: not-allowed;
                }
            }

            .discussion-form-area {
                position: relative;
                width: 100%;
                padding: 0px 8px 0px 12px;

                .input-area {
                    margin-top: 8px;

                    .discuss-reply-input {
                        width: 98%;
                        min-height: 31px;
                        box-sizing: border-box;
                        font-variant: tabular-nums;
                        list-style: none;
                        font-feature-settings: 'tnum';
                        position: relative;
                        display: inline-block;
                        padding: 4px 11px;
                        color: rgba(0, 0, 0, 0.65);
                        background-image: none;
                        border-radius: 4px;
                        font-size: 14px;
                        line-height: 22px;
                        background: rgba(255, 255, 255, 1);
                        border: 1px solid rgba(0, 0, 0, 0.08);
                        overflow: auto;
                        resize: inherit;
                        overflow-x: hidden;
                    }

                    .discuss-reply-input::-webkit-input-placeholder,
                    .discuss-reply-input::-moz-placeholder,
                    .discuss-reply-input:-ms-input-placeholder {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(0, 0, 0, 0.45);
                        line-height: 22px;
                    }
                }
            }

            .mind-discussion-list {
                width: 100%;
                position: relative;
                padding: 17px 17px;

                .mind-discussion-items {
                    margin-bottom: 21px;
                    width: 100%;
                    position: relative;

                    .handler {
                        margin-top: 4px;
                        margin-bottom: 8px;
                    }

                    .resolved-btn,
                    .unsolved-btn {
                        position: absolute;
                        right: 6px;
                        top: 1px;
                        text-align: center;
                        cursor: pointer;
                        width: 52px;
                        height: 20px;
                        border-radius: 4px;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17px;
                    }

                    .resolved-btn {
                        background: rgba(245, 245, 245, 1);
                        border: 1px solid rgba(217, 217, 217, 1);
                        color: rgba(0, 0, 0, 0.45);
                    }

                    .unsolved-btn {
                        background: rgba(255, 241, 240, 1);
                        border: 1px solid rgba(255, 163, 158, 0.5);
                        color: #fe8883;
                    }

                    .user-info {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(110, 119, 138, 1);

                        span {
                            display: inline-block;
                            vertical-align: middle;
                            line-height: 23px;
                            margin-right: 5px;
                            font-size: 12px;
                            font-weight: 400;
                            color: rgba(110, 119, 138, 1);
                            top: -1px;
                        }

                        .span:not(.user-avator) {
                            margin-top: 3px;
                        }

                        .user-avator {
                            .ant-avatar {
                                top: -3px;
                            }
                        }
                    }

                    .discussion-title {
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 22px;
                        margin-top: 1px;
                        word-wrap: break-word;
                        word-break: break-all;
                        overflow: hidden;
                    }

                    @replyPaddingLeft: 20px;

                    .discussion-reply-handler-area {
                        margin-bottom: 4px;
                        min-height: 17px;

                        .handler {
                            margin: 0px;
                        }
                    }

                    .discussion-reply-handler {
                        .handler {
                            font-size: 12px;
                            font-weight: 400;
                            color: rgba(110, 119, 138, 1);
                            line-height: 17px;
                            cursor: pointer;

                            .reply-btn {
                                font-size: 12px;
                                font-weight: 400;
                                color: rgba(110, 119, 138, 1);
                                line-height: 17px;
                            }
                        }
                    }

                    .discussion-reply-area {
                        position: relative;
                        padding: 9px 9px;
                        background: rgba(245, 245, 245, 1);
                        display: none;

                        .discuss-reply-input {
                            width: 100%;
                        }

                        .btn-area {
                            padding-right: 2px;
                        }

                        .reply-item {
                            position: relative;
                            padding-left: @replyPaddingLeft;

                            .ant-tooltip-arrow {
                                border-top-color: #fff !important;
                            }

                            .ant-tooltip-inner {
                                max-width: 300px;
                                overflow: hidden;
                                word-wrap: break-word;
                                word-break: break-all;
                                color: #000 !important;
                                background-color: #fff !important;
                            }

                            .user-info {
                                .ant-avatar {
                                    margin-left: -20px;
                                }
                            }

                            .discussion-reply-input-area {
                                position: relative;
                                padding-left: 0px;

                                .user-info {
                                    left: 0px;

                                    .ant-avatar {
                                        margin-left: -@replyPaddingLeft;
                                    }
                                }

                                .discussion-form-area {
                                    padding-left: 0px;
                                }
                            }

                            .adopt {
                                position: absolute;
                                cursor: pointer;
                                background: rgba(0, 0, 0, 0.25);
                                width: 16px;
                                height: 11px;
                                display: none;
                                left: 0px;
                                top: 24px;
                                -webkit-mask: url(../../../assets/svg-icons/adopt.svg) no-repeat 50% 50%;
                                mask: url(../../../assets/svg-icons/adopt.svg) no-repeat 50% 50%;
                            }

                            .un-adopt {
                                display: none;
                            }

                            .adopted {
                                display: inline-block;
                                background: rgba(9, 187, 7, 1);
                            }

                            .reply-to {
                                font-size: 14px;
                                display: inline;
                                margin-left: 5px;
                                margin-right: 0px;
                                vertical-align: inherit;

                                span {
                                    margin-right: 0px;
                                    vertical-align: auto;
                                }

                                line-height: 20px;

                                .reply-to-icon {
                                    cursor: pointer;
                                    vertical-align: initial;
                                    margin-right: 0px;
                                    width: 14px;
                                    height: 14px;
                                    display: inline-block;
                                    background: url(../../../assets/svg-icons/discuss-reply-contont.svg) no-repeat 0px
                                        0px;
                                    background-size: contain;
                                }
                            }
                        }

                        .content {
                            overflow: hidden;
                            font-size: 14px;
                            font-weight: 400;
                            color: rgba(0, 0, 0, 0.85);
                            line-height: 20px;
                            margin-left: 8px;
                            word-wrap: break-word;
                            word-break: break-all;
                        }

                        .discussion-reply-input-area {
                            position: relative;
                            padding-left: @replyPaddingLeft;

                            .discussion-form-area {
                                padding-left: 0px;
                            }

                            .user-info {
                                position: absolute;
                                left: 0px;
                                top: 0px;
                            }
                        }
                    }
                }
            }
        }

        .mind-members-tab {
            .invite-members-area {
                padding-left: 17px;
                padding-right: 17px;
                position: relative;
                margin: 10px 0px;
                width: 100%;
                height: auto;

                .title {
                    height: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.85);
                    line-height: 20px;
                }

                .input-btn {
                    padding: 5px 0px;

                    .ant-input {
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 26px;
                        height: 28px;
                        background: rgba(255, 255, 255, 1);
                        border-radius: 4px 0px 0px 4px;
                        border: 1px solid rgba(0, 0, 0, 0.15);
                    }

                    .ant-btn:visited {
                        border: 0px;
                    }

                    .ant-btn {
                        padding: 0px 0px;
                        text-align: center;
                        border: 0px;
                        width: 66px;
                        height: 28px;
                        background: rgba(40, 48, 64, 0.9);
                        border-radius: 0px 4px 4px 0px;
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 1);
                    }
                }

                .in-date {
                    height: 17px;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.45);
                    line-height: 17px;
                }
            }

            .member-list-area {
                padding-left: 17px;
                padding-right: 17px;
                position: relative;
                width: 100%;
                height: auto;

                .title {
                    height: 32px;
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.85);
                    line-height: 31px;
                    border-bottom: 1px solid #ededed;
                }

                .list {
                    .item {
                        text-align: left;
                        height: 36px;
                        line-height: 36px;
                        padding-left: 7px;
                        border-bottom: 1px solid #ededed;

                        .user-avator {
                            .ant-avatar {
                                top: -3px;
                            }
                        }

                        .name {
                            padding-left: 9px;
                        }
                    }
                }
            }
        }

        .mind-shortcut-tab {
            .shortcut-keys-area {
                position: relative;
                padding: 0px 18px 0px 12px;

                .item {
                    padding-left: 5px;
                    border-bottom: 1px solid #ededed;
                    height: 36px;
                    line-height: 36px;
                    text-align: left;
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.85);

                    .key-area {
                        display: inline-block;
                        float: right;
                        text-align: right;

                        .key-name {
                            cursor: default;
                            height: 15px;
                            line-height: 15px;
                            padding: 3px 5px;
                            background: rgba(255, 255, 255, 1);
                            border-radius: 4px;
                            border: 1px solid rgba(217, 217, 217, 1);

                            font-size: 14px;
                            font-weight: 400;
                            color: rgba(0, 0, 0, 0.85);
                        }
                    }
                }
            }
        }
    }

    h1 {
        color: #333;
        font-size: 20px;
    }

    h2 {
        color: #444;
        font-size: 14px;
    }

    hr {
        margin-top: 12px;
        margin-bottom: 8px;
        border: 0;
        height: 1px;
    }

    .mind-tw-block {
        padding-left: 16px;
    }

    .mind-tw-row {
        button {
            margin-left: 4px;
            margin-right: 4px;
        }

        padding-top: 8px;
        margin-bottom: 8px;
    }

    .mind-tw-label {
        margin-right: 4px;
    }

    .mind-tw-value {
        border-left: 2px solid #888888;
        border-right: 2px solid #888888;
        border-radius: 6px;
        padding: 3px 4px;
        background-color: #fcfcfc;
    }

    .mind-tw-input {
        border: 1px solid #888888;
        padding-left: 4px;
        border-radius: 4px;
    }
}

/*#region 画布右侧工具栏显示隐藏动画 */
.mind-tool-move-right {
    flex: 0 0 0px;
    border-left: 0px;
    box-shadow: none;
    transition: flex 0.5s;
}

.mind-tool-move-left {
    flex: 0 0 420px;
    border-left: 1px solid rgba(232, 232, 232, 1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    transition: flex 0.5s;
}

/*#endregion*/
.reply-popover-text {
    display: inline-block;
    position: relative;
    max-width: 330px;
    height: auto;
    overflow: hidden;
}
