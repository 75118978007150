.mind-style-pane {
    .mind-style-pane-content {
        background-color: rgba(255, 255, 255, 1);
        padding-left: 11px;
        padding-right: 8px;
        padding-top: 12px;

        .ant-radio-button-wrapper {
            padding-top: 2px;
            padding-bottom: 2px;
            line-height: 18px;
            height: 24px;
            font-size: 12px;
        }

        .ant-select-selection {
            line-height: 24px;
            height: 24px;
            font-size: 12px;

            .ant-select-selection__rendered {
                height: 24px;
                line-height: 24px;
            }
        }

        .ant-dropdown-trigger {
            line-height: 24px;
            height: 24px;
            font-size: 12px;
            border: 1px solid #d9d9d9;
            border-radius: 4px;

            .anticon {
                margin-right: 8px;
                // 修正 svg 大小, 比 .ant-select-selection 中同样 12px 的 svg 看上去要小, 原因未知
                svg {
                    width: 14px;
                    height: 14px;
                }
            }
        }

        .title {
            font-size: 16px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);

            &.multiple {
                color: rgba(0, 0, 0, 0.65);
                font-style: italic;
            }
        }

        hr {
            height: 1px;
            background: rgba(237, 237, 237, 1);
        }

        section {
            margin-left: 4px;
            font-size: 12px;

            header {
                color: rgba(0, 0, 0, 0.45);
            }

            .row {
                margin-top: 8px;
            }

            .label {
                margin-right: 16px;
            }

            .prop-value {
                margin-right: 12px;
            }
        }
    }
}
