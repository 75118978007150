@import '~antd/dist/antd.less'; // 引入官方提供的 less 样式入口文件
html,
body,
#root {
    width: 100%;
    // use inner scroll instead of page scroll
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, 'PingFang SC',
        'Hiragino Sans GB', 'Microsoft YaHei', SimSun, sans-serif;
    font-size: 14px;
}

/* disable the blue box around the focused element in chrome. */
*:focus {
    outline: none;
}

// 定制ant的主题，修改tab的Height
@tabs-card-height: 30px;
