.nw-dashboard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .nw-appbar {
        position: static;
        flex-grow: 0;

        background-color: rgba(255, 255, 255, 1);
        opacity: 0.9;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.06); // 0 2px 3px 0 rgba(100, 100, 100, 0.06);

        .nw-logo {
            font-size: 38px;
            flex-grow: 1;
        }
    }

    .main {
        flex-grow: 1;
        display: flex;
        flex-direction: row;

        .left-sidebar {
            flex-grow: 0;
            width: 174px;
            min-width: 174px;
            height: 100%;

            padding-top: 34px;

            background: rgba(245, 245, 245, 1);

            .sidebar-item {
                text-align: center;
                height: 32px;

                color: rgba(0, 0, 0, 0.85);
            }
        }

        .content {
            flex-grow: 1;
            overflow: auto;

            background-color: rgba(250, 250, 250, 1);

            padding-left: 44px;
            padding-right: 14px;
            padding-top: 26px;

            .table-wrapper {
                margin: auto;
                cursor: default;

                .document-title {
                    cursor: pointer;
                }

                thead {
                    height: 40px;

                    tr {
                        border-bottom: 2px solid;
                    }
                }

                th {
                    font-size: 12px;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.65);
                }
            }
        }

        .right-sidebar {
            flex-grow: 0;
            width: 204px;
            min-width: 174px;
            height: 100%;
            padding-top: 28px;
            padding-left: 30px;

            background-color: rgba(250, 250, 250, 1);

            button {
                width: 126px;
                height: 32px;
                border-color: #41464b;
                border-radius: 4px;
                background-color: rgba(60, 68, 84, 1);
                font-weight: 500;
                color: rgba(255, 255, 255, 1);

                &:disabled {
                    opacity: 0.5;
                }
            }
        }
    }
}
