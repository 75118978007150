.main-appbar {
    position: relative;
    width: 100%;
    height: 56px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.06);
    @maxRight: 360px;
    @leftMaxWidth: 500px;

    .left-view {
        height: 100%;
        position: relative;
        display: inline-block;
        width: @leftMaxWidth;
        max-width: @leftMaxWidth;
        // width: calc(~'100% - @{maxRight}');
        margin-left: 20px;
        overflow: hidden;

        .anticon {
            vertical-align: initial;
            position: absolute;
            left: 0px;
            top: 18px;
        }

        .logo {
            margin-top: 10px;
            width: auto;
            height: 36px;
            line-height: 36px;
            height: 36px;
            background: #ffffff url(../assets/svg-icons/logo.svg) no-repeat 0px 0px;
            background-size: contain;

            span {
                font-size: 18px;
                font-weight: 400;
                color: rgba(40, 48, 64, 0.9);
                line-height: 36px;
            }

            :first-child {
                margin-left: 40px;
                font-weight: 600;
            }
        }
    }

    .bar-right-handler {
        display: inline-block;
        width: auto;
        max-width: @maxRight;
        text-align: right;
        float: right;
        padding-right: 25px;
        line-height: 56px;
        height: 100%;

        .user-avator,
        .dropdown-handler,
        .notice {
            display: inline-block;
        }

        .user-avator {
            width: 24px;
            margin-right: 10px;
        }

        .notice {
            // 隐藏0.1版未实现功能
            display: none;
            min-width: 22px;
            margin-right: 25px;

            .notice-icon {
                background-color: #333;
                margin-top: 1px;
                width: 22px;
                height: 20px;
                display: inline-block;
                vertical-align: middle;
                -webkit-mask: url(../assets/svg-icons/top-notice.svg) no-repeat 50% 50%;
                mask: url(../assets/svg-icons/top-notice.svg) no-repeat 50% 50%;
            }
        }
    }
}
