.not-chrome {
    width: 500px;
    height: 110px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -250px;
    margin-top: -55px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    cursor: pointer;

    .icon {
        margin-left: 175px;
        width: 150px;
        height: 60px;
        background: #ffffff url(../assets/svg-icons/logo.svg) no-repeat left top;
        background-size: contain;
        margin-bottom: 5px;

        span {
            font-size: 18px;
            color: rgba(40, 48, 64, 0.9);
            line-height: 60px;
            margin-left: 10px;
            font-weight: 600;
        }
    }
}
