@import '../../../styles/common-styles';

@admin-drawer-width: 240px;
@admin-drawer-zIndex: 5000;

.admin-bench {
    display: flex;

    .main-appbar {
        position: fixed;
        /* 让 appbar 保持在 drawer 之上. */
        z-index: @admin-drawer-zIndex + 1;
    }

    .admin-drawer {
        width: @admin-drawer-width;
        margin-top: @nw-appbar-height;
        flex-shrink: 0;
    }

    // TODO: 具体的样式是什么?
    .admin-drawer-paper {
        width: @admin-drawer-width;
    }

    .admin-container {
        margin-top: @nw-appbar-height;
        flex-grow: 1;
        padding: 20px;
    }

    .user-list-pane {
        .column-title {
            font-size: 1.4em;
        }

        .head-buttons {
            margin-top: 20px;
            margin-bottom: 20px;
            float: right;
        }
    }

    .form-title {
        font-size: 1.8em;
    }

    .form-row {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .form-button {
        margin-right: 20px;
    }
}
