.mind-tool-bar-wrapper {
    position: relative;

    .mind-tool-bar {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;

        .mind-tool-bar-content {
            z-index: 10;
            height: 36px;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            position: fixed;

            .center {
                display: flex;
                align-items: center;
                height: 100%;

                padding-left: 8px;
                padding-right: 8px;
                background: rgba(250, 250, 250, 1);
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);

                .scale {
                    min-width: 54px;
                    text-align: center;
                    padding-left: 4px;
                    padding-right: 4px;
                }
            }
        }
    }
}
