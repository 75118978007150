/******************************************************************************/
//  Mind 各可视化组件的样式.
/******************************************************************************/

@mind-primary-background-color: rgba(245, 245, 245, 1);

/*.mg-scrollbar {
    &::-webkit-scrollbar {
        width: 7px; //for vertical scrollbar
        height: 7px; //for horizontal scrollbar
        background-clip: content-box;
        background-color: #f9f9f9;
    }

    &::-webkit-scrollbar-track {
        background-color: #999999;
        border-width: 3px;
        border-style: solid;
        border-color: transparent;
        background-clip: content-box;

        &:hover {
            border-color: lightgray;
        }
    }

    &::-webkit-scrollbar-thumb {
        background-color: #696969;
        border: 1px solid #696969;
        border-radius: 3px;
    }
}*/

.mind-workbench {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .mind-appbar {
        position: static;
        flex-grow: 0;

        background-color: rgba(255, 255, 255, 1);
        opacity: 0.9;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.06); // 0 2px 3px 0 rgba(100, 100, 100, 0.06);

        .mind-app-title {
            font-size: 38px;
            flex-grow: 1;
        }
    }

    .mind-main-content {
        flex-grow: 1;
        display: flex;
        flex-flow: row;
        -ms-flex-direction: row;

        background-color: #fcfcfc;

        .mind-board {
            flex-grow: 1;
            overflow: hidden;
            /*  .mg-scrollbar();*/

            .mind-canvas-container {
                position: relative;
                width: auto;
                height: auto;
                user-select: none;

                .mg-canvas {
                    min-width: 0;
                    min-height: 0;
                }
            }

            // 结点文字编辑框
            .mind-inplace-text-editor {
                padding: 4px;
                min-width: 200px;

                .wrapper {
                    width: 100%;
                    // height: 100%;
                    background-color: white;
                    border-width: 1px;
                    border-style: solid;
                    border-color: slategrey;
                    border-radius: 2px;
                    padding-left: 8px;
                    box-shadow: 2px 4px 4px 4px rgba(0, 0, 0, 0.1);

                    .text-input {
                        display: inline-block;
                        border: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .mind-tool-switch-btn {
        cursor: pointer;
        position: fixed;
        right: 18px;
        width: 22px;
        height: 22px;
        text-align: center;
        line-height: 22px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        border-radius: 3px;

        .mind-tool-open-icon,
        .mind-tool-close-icon {
            background-color: #333;
            display: inline-block;
            margin: 4px 4px;
            width: 14px;
            height: 14px;
        }

        .mind-tool-open-icon {
            -webkit-mask: url(../../assets/svg-icons/move-left-tool.svg) no-repeat 50% 50%;
            mask: url(../../assets/svg-icons/move-left-tool.svg) no-repeat 50% 50%;
        }

        .mind-tool-close-icon {
            -webkit-mask: url(../../assets/svg-icons/move-right-tool.svg) no-repeat 50% 50%;
            mask: url(../../assets/svg-icons/move-right-tool.svg) no-repeat 50% 50%;
        }
    }
}
