.nw-toolbar-button {
    width: 24px;
    height: 24px;
    padding: 2px 2px 2px 2px;

    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);

    &.disabled {
        opacity: 0.5;
    }

    &:hover {
        // background-color: #d8d8d8;
        border-style: solid;
        border-color: #d8d8d8;

        &.disabled {
            border-style: dashed;
        }
    }
}
