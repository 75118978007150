@mind-primary-background-color: rgba(245, 245, 245, 1);

.mg-canvas {
    border-width: 1px;
    border-color: @mind-primary-background-color;
}

@mg-base-color: #999999;
@mg-highlight-color: #666666;

.mg-widget {
    .mg-body {
        cursor: pointer;

        .mg-border {
            stroke: rgb(73, 73, 73); // @mg-base-color;
            fill: white;

            &:hover {
                stroke-width: 4px;
            }

            &.mg-selected {
                stroke: @mg-highlight-color;
                stroke-width: 2px;

                &:hover {
                    stroke: @mg-highlight-color;
                    stroke-width: 4px;
                }
            }
        }

        .mg-text {
            pointer-events: none;

            color: #494949;
        }

        .mg-discussion-icon {
            pointer-events: bounding-box;

            .unread-discussion {
                stroke: #fe8883;
                &:hover {
                    stroke: rgba(245, 34, 45, 1);
                }
            }

            &:hover {
                stroke: rgba(0, 0, 0, 0.85);
            }
        }
    }

    .mg-pole {
        stroke: @mg-base-color;
    }

    .mg-grip {
        cursor: pointer;
        stroke: #cccccc;
        fill: @mg-base-color;

        &.collapsed {
            stroke: black;
        }

        .mg-grip-border {
            fill: white;
        }

        .mg-arrow {
            pointer-events: none;

            fill: white;
        }

        &:hover {
            stroke: @mg-highlight-color;

            .mg-grip-border {
                stroke-width: 2px;
            }

            .mg-arrow {
                fill: @mg-highlight-color;
            }
        }
    }
}

.mg-link {
    stroke: #aaaaaa;
    fill: none;
    cursor: pointer;
}

.mg-selected-border {
    pointer-events: none;

    cursor: pointer;
    fill: none;
    stroke: #0277bd;
    stroke-width: 3px;

    &:hover {
        stroke: @mg-highlight-color;
        stroke-width: 2px;
    }
}

/** 用鼠标拖放选择结点时, 表示选择范围的矩形框. */
.mg-selecting-box {
    stroke: #0091ea;
    stroke-width: 2px;
    fill: #40c4ff;
    opacity: 0.2;
}

.mg-drag-source-box {
    stroke: #333333;
    stroke-width: 1px;
    fill: #ffde03;
    opacity: 1;
}

.mg-drop-stub {
    pointer-events: none;
    fill: #40c4ff;
    opacity: 0.5;
}

.mg-drop-stub-link {
    stroke: #40c4ff;
    stroke-width: 1px;
}

.mg-drop-direction {
    pointer-events: none;
    opacity: 0.5;

    &.allow {
        fill: #c8e6c9;
    }

    &.not-allow {
        cursor: not-allowed;
        fill: #ef9a9a;
    }
}

.mg-debug-root-region {
    fill: none;
    stroke: #8c8b8b;
    stroke-width: 1;
    //noinspection CssInvalidPropertyValue
    stroke-dasharray: 4 1;
}

.mg-debug-svg-ctm-origin {
    fill: #ef9a9a;
}

.mg-debug-svg-origin {
    fill: #333333;
}
