/******************************************************************************/
//  账号管理 各可视化组件的样式.
/******************************************************************************/

// 登录/注册页
.account-page {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;

    .big-button {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
