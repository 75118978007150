// account
input[type=password]{
    ime-mode: disabled;
}

.nw-login-page {
    background: rgba(250, 250, 250, 1);
    /*antd css*/
    .ant-form-explain {
        min-height: auto;
        height: 20px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        padding-left: 9px;
    }
    .ant-form-item {
        margin-bottom: 14px;
    }
    .ant-form-item-with-help {
        margin-bottom: 10px;
    }
    .ant-form-item-control {
        line-height: 36px;
        vertical-align: top;
    }
    .register-form .ant-input-affix-wrapper .ant-input:not(:first-child) {
        padding-left: 68px;
    }
    .bind-form .ant-input-affix-wrapper .ant-input:not(:first-child) {
        padding-left: 54px;
    }
    .ant-input {
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        height: 36px;
    }
    .ant-checkbox-wrapper {
        line-height: 20px;
        font-size: 12px;
    }
    .ant-tabs-tab {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
    }
    .ant-tabs-nav .ant-tabs-tab {
        width: 80px;
        padding: 10px 0 4px 0;
        margin: 0 38px;
        text-align: center;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: rgba(0, 0, 0, 0.85);
    }
    .ant-tabs-ink-bar {
        background-color: rgba(0, 0, 0, 0.85);
    }
    .ant-tooltip-inner {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        background: rgba(0, 0, 0, 0.75);
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
        width: 140px;
    }
    .has-error .ant-input-prefix {
        color: rgba(0, 0, 0, 0.85);
    }
    /*去掉数字输入框的上下箭头*/
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
}
.nw-login-header {
    padding: 10px 0 10px 50px;
    height: 56px;
    line-height: 36px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(40, 48, 64, 0.9);
    background: rgba(255, 255, 255, 1);
    image {
        width: 36px;
        height: 36px;
    }
}

.nw-login-poster {
    margin-top: 96px;
    margin-left: 140px;
    width: 671px;
    height: 475px;
}

.nw-login-content-bg {
    width: 382px;
    //height: 416px;
    min-height: 416px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.15);
    padding: 7px 8px 6px 8px;
    border-radius: 4px;
    top: 138px;
    right: 114px;
    position: absolute;
    .nw-login-content {
        width: 366px;
        //height: 403px;
        min-height: 403px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        position: relative;
        .nw-login-content-header {
            width: 200px;
            height: 47px;
            padding-top: 19px;
            margin: 0 auto;
            font-size: 20px;
            font-weight: 500;
            color: rgba(60, 68, 84, 1);
            line-height: 28px;
            text-align: center;
        }
        .nw-login-content-center {
            width: 316px;
            margin: 20px auto 0 auto;
            padding-bottom: 50px;
            .nw-login-submit-item {
                margin-top: -8px;
                .nw-login-form-toggle {
                    float: right;
                    color: rgba(0, 0, 0, 0.65);
                }
                button {
                    display: block;
                    width: 100%;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 1);
                    background: rgba(40, 48, 64, 0.9);
                    border-color: rgba(40, 48, 64, 0.9);
                    border-radius: 4px;
                    height: 36px;
                }
            }
            .nw-login-divider {
                color: rgba(0, 0, 0, 0.65);
                font-size: 12px;
                margin-top: -9px;
                height: 20px;
                line-height: 20px;
                position: relative;
                .nw-login-divider-line {
                    position: absolute;
                    top: 10px;
                    height: 1px;
                    width: 100%;
                    background: rgba(229, 229, 229, 1);
                }
                .nw-login-divider-desc {
                    margin: 0 auto;
                    width: 100px;
                    background-color: rgba(255, 255, 255, 1);
                    text-align: center;
                    position: relative;
                }
            }
            .nw-login-wechat-button {
                margin-top: 14px;
                width: 316px;
                height: 36px;
                font-size: 14px;
                border-radius: 4px;
                border: 1px solid rgba(95, 194, 95, 1);
                color: rgba(95, 194, 95, 1);
                display: block;
                line-height: 36px;
                text-align: center;
            }
            .nw-login-form-button {
                width: 316px;
                height: 36px;
                font-size: 14px;
                display: block;
                color: rgba(255, 255, 255, 1);
                background: rgba(40, 48, 64, 0.9);
                border-color: rgba(40, 48, 64, 0.9);
                border-radius: 4px;
            }
            .nw-other-login {
                text-align: center;
                width: 120px;
                margin: 0 auto;
                cursor: pointer;
                position: absolute;
                bottom: 50px;
                left: 123px;
                a {
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(60, 68, 84, 1);
                }
            }
            // .nw-password-input,
            .nw-smscode-input {
                padding-right: 105px;
            }
            .password-button {
                width: 102px;
                height: 36px;
                background: rgba(40, 48, 64, 0.9);
                border-color: rgba(40, 48, 64, 0.9);
                border-radius: 0 2px 2px 0;
                font-size: 14px;
                line-height: 36px;
                font-weight: 400;
                text-align: center;
                position: absolute;
                right: 0;
                top: -8px;
                a {
                    color: rgba(255, 255, 255, 1);
                }
            }
            .nw-smscode-button {
                width: 102px;
                height: 36px;
                background: rgba(0, 0, 0, 0.25);
                border-radius: 0 2px 2px 0;
                font-size: 14px;
                line-height: 36px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                cursor: not-allowed;
                text-align: center;
                position: absolute;
                right: 0;
                top: -8px;
            }
            .nw-smscode-button-clickable {
                cursor: pointer;
                background: rgba(40, 48, 64, 0.9);
            }
            .nw-nickname {
                padding-right: 36px;
            }
            .nw-register-service-agreement {
                width: 184px;
                height: 17px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
                line-height: 17px;
                margin-top: -6px;
            }
            .nw-in-wechat {
                margin: 16px auto 0 auto;
                text-align: center;
            }
        }
        .nw-login-content-center-hastab {
            margin-top: 0;
        }
        .nw-login-content-footer {
            position: absolute;
            bottom: 27px;
            width: 100%;
            .nw-register {
                width: 122px;
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
                line-height: 20px;
                margin: 0 auto;
                text-align: center;
                .nw-reg {
                    font-weight: 500;
                    color: rgba(60, 68, 84, 1);
                }
                div {
                    width: 30px;
                    margin: 0 auto;
                    cursor: pointer;
                }
            }
        }
        .prefix-divider {
            display: inline-block;
            border-right: 1px solid #d8d8d8;
            height: 16px;
            width: 0px;
            margin-left: 6px;
            position: absolute;
            top: 3px;
        }
    }
}
.nw-input-on-after {
    position: absolute;
    right: 0;
    top: -8px;
    padding-right: 10px;
}
.nw-account-model {
    /*model css*/
    .ant-modal-header {
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        padding-bottom: 8px;
    }
    .ant-modal-body {
        font-size: 14px;
        padding-top: 0;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
    }
    .ant-modal-footer {
        border: none;
        padding-top: 0;
        padding-bottom: 20px;
        button {
            padding: 0 12px;
            height: 24px;
            line-height: 22px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
        }
        button:first-child:hover {
            color: rgba(0, 0, 0, 0.65);
            border-color: #d9d9d9;
        }
        .ant-btn-primary {
            background: rgba(40, 48, 64, 0.9);
            border-color: rgba(40, 48, 64, 0.9);
            color: rgba(255, 255, 255, 1);
        }
    }
}
