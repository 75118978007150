.mind-document-title {
    @loadingWidth: 90px;
    margin-top: 17px;
    position: relative;
    width: auto;
    max-width: calc(~'100% - @{loadingWidth}');
    min-width: 80px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    margin-left: 25px;
    overflow: hidden;

    .title-text,
    .title-input {
        position: relative;
        width: auto;
        height: 24px;
        line-height: 19px;
        display: inline-block;
        border: 0px;
        outline: none;
        border-bottom: 2px solid dodgerblue;
        box-shadow: none;
        border-radius: 0;
        padding-bottom: 4px;
    }

    .title-text {
        border-bottom: 0px;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            border-bottom: 2px solid #999999;
        }
    }
}

.save-loading,
.doc-loading,
.title-readonly {
    margin-left: 10px;
    line-height: 22px;
    display: inline-block;
    position: absolute;
    width: 30px;
    height: 22px;
    top: 1px;
}

.doc-loading {
    margin-left: 20px;
}

.title-readonly {
    width: 70px;
    color: #ccc;
    top: 17px;
    font-size: 12px;
    margin-left: 10px;

    .icon {
        background: #ffffff url(../../assets/svg-icons/only-read.svg) no-repeat 0px 0px;
        padding-left: 22px;
        background-size: contain;
    }
}
