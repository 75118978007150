.mind-color-picker {
    display: inline-flex;

    .color-box {
        display: inline-block;
        margin-left: 11px;
        margin-top: 2px;
        margin-right: 11px;
        width: 36px;
        height: 18px;
        font-style: italic;
        line-height: 18px;
        padding-left: 4px;
        vertical-align: center;

        border: 1px solid #d9d9d9; // ant-d selection border color
    }
}

.mind-color-picker-popup {
    display: inline-block;
    padding: 4px;
    background-color: wheat;

    .button-container {
        padding: 4px;
        justify-content: right;

        button {
            border: 1px solid #afafaf;
        }
    }
}
